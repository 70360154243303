import { createMedia } from '@artsy/fresnel';

const AppMedia = createMedia({
  breakpoints: {
    xs: 0,
    sm: 768,
  },
});

export const mediaStyles = AppMedia.createMediaStyle();

export const { Media, MediaContextProvider } = AppMedia;
