// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-category-page-tsx": () => import("./../../src/templates/category-page.tsx" /* webpackChunkName: "component---src-templates-category-page-tsx" */),
  "component---src-templates-contact-page-tsx": () => import("./../../src/templates/contact-page.tsx" /* webpackChunkName: "component---src-templates-contact-page-tsx" */),
  "component---src-templates-faq-page-tsx": () => import("./../../src/templates/faq-page.tsx" /* webpackChunkName: "component---src-templates-faq-page-tsx" */),
  "component---src-templates-gift-list-page-tsx": () => import("./../../src/templates/gift-list-page.tsx" /* webpackChunkName: "component---src-templates-gift-list-page-tsx" */),
  "component---src-templates-index-page-tsx": () => import("./../../src/templates/index-page.tsx" /* webpackChunkName: "component---src-templates-index-page-tsx" */),
  "component---src-templates-legal-page-tsx": () => import("./../../src/templates/legal-page.tsx" /* webpackChunkName: "component---src-templates-legal-page-tsx" */),
  "component---src-templates-list-feed-page-tsx": () => import("./../../src/templates/list-feed-page.tsx" /* webpackChunkName: "component---src-templates-list-feed-page-tsx" */),
  "component---src-templates-product-detail-page-tsx": () => import("./../../src/templates/product-detail-page.tsx" /* webpackChunkName: "component---src-templates-product-detail-page-tsx" */),
  "component---src-templates-recipient-occasion-page-tsx": () => import("./../../src/templates/recipient-occasion-page.tsx" /* webpackChunkName: "component---src-templates-recipient-occasion-page-tsx" */)
}

